
import tooltip from '~/mixins/tooltip'

export default {
  name: 'BaseTooltipHtml',
  mixins: [tooltip],
  props: {
    isHintVisible: {
      type: Boolean,
      default: false,
    },

    identifier: {
      type: String,
      default: '',
    },
  },
}
