const availableBlockPlacements = [
  'top', 'right', 'bottom', 'left', 'left-bottom', 'top-right', 'bottom-center', 'bottom-right',
]
const [
  topBlockPlacement,
  rightBlockPlacement,
  bottomBlockPlacement,
  leftBlockPlacement,
  leftBottomPlacement,
  topRightBlockPlacement,
  bottomCenterBlockPlacement,
  bottomRightBlockPlacement,
] = availableBlockPlacements
const arrowPlacementByBlockPlacement = {
  [topBlockPlacement]: 'bottom',
  [rightBlockPlacement]: 'left',
  [bottomBlockPlacement]: 'top',
  [leftBlockPlacement]: 'right',
  [leftBottomPlacement]: 'top-right',
  [topRightBlockPlacement]: 'left-bottom',
  [bottomCenterBlockPlacement]: 'bottom-center',
  [bottomRightBlockPlacement]: 'bottom-right',
}

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },

    placement: {
      type: String,
      default: 'top',
      validator (placement) {
        return availableBlockPlacements.includes(placement)
      },
    },
  },

  data () {
    return {
      arrowPlacementByBlockPlacement,
    }
  },
}
