
export default {
  name: 'BaseTooltip',
  props: {
    arrow: {
      type: String,
      default: 'top',
    },

    placement: {
      type: String,
      default: 'top',
    },
  },
}
