import Vue from 'vue'
import BaseTooltipString from '~/components/base/tooltip/BaseTooltipString'

const elementKeyForTooltip = 'tooltipComponent'
const availableBlockPlacements = ['top', 'right', 'bottom', 'left', 'left-bottom', 'top-right', 'bottom-center', 'bottom-right']

/**
 *
 * @param element {HTMLElement} - element to append tooltip
 * @param options {Object} - options for tooltip
 */
function addTooltipToElement (element, options) {
  if (!(element instanceof Element)) {
    return
  }
  const {
    content,
    placement = availableBlockPlacements[0], // top
    show = false,
    bemModifiers = [],
  } = options

  element.style.cursor = 'pointer'

  if (content && availableBlockPlacements.includes(placement)) {
    const options = {
      scopedSlots: {
        default: () => content,
      },
    }

    const instanceData = {
      placement,
      show,
      bemModifiers,
    }

    const WrapperClass = Vue.extend({
      name: 'Wrapper',
      data () {
        return { instanceData }
      },

      render (h) {
        return h(BaseTooltipString, { ...options, props: { ...this.instanceData } })
      },
    })

    const wrapperInstance = new WrapperClass()

    wrapperInstance.$mount()

    element[elementKeyForTooltip] = wrapperInstance
    element.appendChild(wrapperInstance.$el)
  }
}

/**
 * Method to remove tooltip element from parent
 * @param element {HTMLElement} - element which should contain tooltip
 */
function removeTooltipFromElement (element) {
  if (element && element[elementKeyForTooltip] instanceof Vue) {
    element.removeChild(element[elementKeyForTooltip].$el)
    delete element[elementKeyForTooltip]
  }
}

export default {
  bind (element, binding, vnode) {
    const { value = {} } = binding
    const options = {
      ...value,
      name: vnode.context.$options.name,
    }

    addTooltipToElement(element, options)
  },
  update (element, binding, vnode) {
    const { value = {} } = binding

    Object.entries(value).forEach(([k, v]) => {
      element[elementKeyForTooltip].instanceData[k] = v
    })
  },
  unbind (element, binding, vnode) {
    removeTooltipFromElement(vnode.elm)
  },
}
